/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelComponentPropertiesDto } from '../models/PagedModelEntityModelComponentPropertiesDto';
import type { PagedModelEntityModelGrantAndFunctionDto } from '../models/PagedModelEntityModelGrantAndFunctionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthControllerService {

    /**
     * Get all the grants and functions related to a role
     * @returns PagedModelEntityModelGrantAndFunctionDto A list of grants and a list of functions related to the role
     * @throws ApiError
     */
    public static getGrantsAndFunctions({
page,
size = 10,
sort,
}: {
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelGrantAndFunctionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/grants-funcs',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
    }

    /**
     * Get all components and their properties to be mapped by front-end related to a role
     * @returns PagedModelEntityModelComponentPropertiesDto A list of components and their properties to be mapped by front-end related to a role
     * @throws ApiError
     */
    public static getComponentProperties({
page,
size = 10,
sort,
}: {
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelComponentPropertiesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/component-props',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
    }

}

import { CancelablePromise, OpenAPI } from "../openapi";
import { getAccessToken } from "./jwtUtils";

type inputParams<K> = K & { authorization: string };

type Callback<T, K> = (input: inputParams<K>) => CancelablePromise<T>;

export default async function authorizedCall<T, K>(
  callback: Callback<T, K>,
  input: Omit<inputParams<K>, "authorization"> & {
    authorization?: string;
    page?: number;
    size?: number;
  },
  returnFullData: boolean = false
) {
  const jwt = getAccessToken();
  OpenAPI.TOKEN = jwt != null ? jwt : undefined;

  return callback(input as inputParams<K>)
    .then(async (data: any) => {
      // TODO: manage pagination when returnFullData = true
      if (returnFullData) {
        return data;
      }

      if (typeof data?.page === "undefined") {
        return data?._embedded?.entities ?? [];
      }

      const page = data.page;

      if (page.totalPages <= page.number + 1) {
        return data?._embedded?.entities ?? [];
      }

      input.page = page.number + 1;

      const recursiveResult: T[] = await authorizedCall(callback, input);

      return [...data._embedded.entities, ...recursiveResult];
    })
    .catch((err) => {
      throw err;
    });
}

import { EntityModelGrantAndFunctionDto } from "../../../openapi";
import { Location } from "react-router-dom";
import routePaths from "../../../routes/routePaths.json";
import modals from "../../../config/modals";
import { UserPermission, UserPermissions } from "../types";
import { RoutePath } from "../../../types/RoutePath";

/** Check if the user has permission to access the current route */
export const hasRoutePermission = (
  userPermissions: UserPermissions,
  pathname: Location["pathname"]
) => {
  return !!userPermissions?.some((userPermission) =>
    userPermission?.paths.find((route) => route === pathname)
  );
};

/** Associate one or more routes path to a codeFunction */
export const mapCodeFuncToRoutePaths = (
  codeFunction: UserPermission["codeFunction"] | undefined,
  codeGrant: string
) => {
  if (!codeFunction) return ["/"];

  let filteredRoutePaths = (routePaths as Array<RoutePath>).filter(
    (routePath) => routePath.code === codeFunction
  );

  if (filteredRoutePaths.length === 0) return ["/"];

  let paths = filteredRoutePaths.map((routeObj) => routeObj.path);

  return paths.filter(
    (path) =>
      (codeGrant.includes("C") && path.includes("add")) ||
      (codeGrant.includes("D") && path.includes("delete")) ||
      (codeGrant.includes("U") && path.includes("edit")) ||
      (codeGrant.includes("R") &&
        !["add", "edit", "delete"].some((action) => path.includes(action)))
  );
};

export const mapCodeFuncToModals = (
  codeFunction: UserPermission["codeFunction"] | undefined
) => {
  if (!codeFunction) return undefined;

  return modals.find((m) => m.code === codeFunction);
};

/** Create a user permission obj from a grant and function obj. */
export const createUserPermission = (
  grantAndFuncObj: EntityModelGrantAndFunctionDto
): UserPermission => ({
  id: grantAndFuncObj.function.id,
  codeFunction: grantAndFuncObj.function.codeFunction,
  description: grantAndFuncObj.function.description,
  codeGrant: grantAndFuncObj.grant,
  functionClass: grantAndFuncObj.function.functionClass,
  paths: [],
});

/** Create an array of user permission from a grant and function array */
export const mapGrantsFuncToUserPermissions = (
  grantAndFuncArr: EntityModelGrantAndFunctionDto[]
): UserPermissions => {
  const _userPermissions: UserPermission[] = [];

  const result: UserPermission[] = grantAndFuncArr.reduce(
    (userPermissions, func) => {
      const currFuncGrant = func.grant;

      let userPermission = userPermissions.find(
        (up) => up?.id === func.function.id
      );

      if (userPermission) {
        userPermission.codeGrant += "|" + currFuncGrant;
      } else {
        userPermission = createUserPermission(func);

        if (userPermission.functionClass !== null) {
          userPermissions.push(userPermission);
        }
      }

      return userPermissions;
    },
    _userPermissions
  );

  for (const grantItem of result) {
    grantItem.paths = mapCodeFuncToRoutePaths(
      grantItem.codeFunction,
      grantItem.codeGrant
    );

    grantItem.modal = mapCodeFuncToModals(grantItem.codeFunction);
  }

  return result;
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityModelTaxFormOutputDto } from '../models/EntityModelTaxFormOutputDto';
import type { PagedModelEntityModelFiscalYearDto } from '../models/PagedModelEntityModelFiscalYearDto';
import type { TaxFormInputDto } from '../models/TaxFormInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxFormControllerService {

    /**
     * Returns a paged list of Tax records
     * @returns EntityModelTaxFormOutputDto Paged list of Tax records
     * @throws ApiError
     */
    public static getTaxForms({
authorization,
}: {
authorization: string,
}): CancelablePromise<EntityModelTaxFormOutputDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax-form',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                401: `Unauthorized user`,
            },
        });
    }

    /**
     * Recalculates tax-form sending the desired Booking Center and Fiscal Year
     * @returns EntityModelTaxFormOutputDto Recalculated successfully
     * @throws ApiError
     */
    public static recalculateTax({
authorization,
requestBody,
}: {
authorization: string,
requestBody: TaxFormInputDto,
}): CancelablePromise<EntityModelTaxFormOutputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax-form',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized user`,
            },
        });
    }

    /**
     * List fiscal years available in tax-form
     * @returns PagedModelEntityModelFiscalYearDto Fiscal years list
     * @throws ApiError
     */
    public static getFiscalYears({
authorization,
page,
size = 10,
sort,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelFiscalYearDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax-form/fiscal-years',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized user`,
            },
        });
    }

}
